import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "SSH To Guest or Remote OS",
  "component": "none",
  "date": "2020-04-03T22:45:13.234Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I often have to work with virtual machines and/or remote servers. When its more convenient to use local developer tools, I rely on SSH and SSHFS. This post describes how to setup SSH to connect to a Ubuntu VirtualBox VM. The information can also be applied to other remotes hosts. Connecting to a remote OS via SSH is the first step in extending the use of your local tools for remote development. A subsequent blog post describes how to configure SSHFS.`}</p>
    <div className="gap-30"></div>
    <h2 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}>{`Table of Contents`}<a parentName="h2" {...{
        "href": "#table-of-contents",
        "aria-label": "table of contents permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setup-virtual-box"
        }}>{`Setup Virtual Box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#communicate-with-the-guest-os-ssh"
        }}>{`Communicate with the guest OS (SSH)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#login-with-ssh-keys"
        }}>{`Login with SSH keys`}</a></li>
    </ul>
    <undefined></undefined>
    <div className="gap-30"></div>
    <h3 {...{
      "id": "setup-virtual-box",
      "style": {
        "position": "relative"
      }
    }}>{`Setup Virtual Box`}<a parentName="h3" {...{
        "href": "#setup-virtual-box",
        "aria-label": "setup virtual box permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Download and install `}<a parentName="p" {...{
        "href": "https://www.virtualbox.org/wiki/Downloads"
      }}>{`VirtualBox`}</a>{` to the host operating system (host OS). Create or import a new virtual machine with your preferred OS. For this post, Ubuntu OS (guest OS) will be running inside of Oracle VirtualBox.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df97c12adf153f434706caa1ed7d9179/161ec/manager-window-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACj0lEQVQ4y31TyW4TQRAdCTgCB+AcFgkhIi4JRy658ilwJQfyW0gI4qyT8dieONger8ksPfuM7cjE29jxwqOrwSAQYqSn6lZPv3qvqlpyXBdhGHGEIsZRCDtso8XXHTsPh/mouxoMp4nADxGEASLxL1/7PnyPg0fP81BQ8pA0TYNtnsOxTTDLgMPX1XMTJ5UajPIhbMtBzTiD7Rj8kg/XddAyLJi2jZAnj5MESTsRhPuf9yB9yBzhSGugULWRq5goWR3UWRva6RkMk2E8HsFzA1imhSiK0O10kFx+ReZTBvsfd1GQVSh7x2jWGtDUAqS9oo6DUoCSO0bFT/ElmKHKLmEZFyJrHMeQZRmZzC6KxSIaDbLu4vWLV3h+cw0bt59iTbqH92+2US3rkIqlCphtYTpJsVzMsZzPMJ5McWEzDAZ9zOdzGIYh1E0mE7FPkhhbjzewfmMNL+88wyPpPnbebkMvV4iwKoo7HI0wmU5xfX2N3lUfucIpLMsSBR8MBpjNZpjyc/riOMLWk02s33qIzbtE+IArfIdKqQzJYg5MkzeEMV5wV4C5Hor8kBKRularxa02xD9E3O/3oWXzUA8V5I+zUA5k1PQa8icqJMdhcBxHgC4IeAHknIZWsykUdbtdoZLsEpbLJf7+vnHk5CykFYkg/RltbjWnZkUTyLKu60LhYrEQdSSk45RPwFggTVMMh0Nkj5UfhL8UCpU23CBCqVoXM0cWe72ewErhv0BJqAx/EjKKfM/rqChZqKrKO5qIDq8w4s1bgdRRJDJq2Hmt+dvyipiiz5+VXqvz5xWIS2Sb5pFApBQpUbvdFvur/pUgFYQrdSvLVEfG32yBjxO96wVvANXuf5hzTPnstqpNfAfKTZdTEigFfwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "VirtualBox Manager Window with VMs",
            "title": "VirtualBox Manager Window with VMs",
            "src": "/static/df97c12adf153f434706caa1ed7d9179/0a47e/manager-window-1.png",
            "srcSet": ["/static/df97c12adf153f434706caa1ed7d9179/8a4e8/manager-window-1.png 150w", "/static/df97c12adf153f434706caa1ed7d9179/5a46d/manager-window-1.png 300w", "/static/df97c12adf153f434706caa1ed7d9179/0a47e/manager-window-1.png 600w", "/static/df97c12adf153f434706caa1ed7d9179/1cfc2/manager-window-1.png 900w", "/static/df97c12adf153f434706caa1ed7d9179/c1b63/manager-window-1.png 1200w", "/static/df97c12adf153f434706caa1ed7d9179/161ec/manager-window-1.png 1840w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "communicate-with-the-guest-os-ssh",
      "style": {
        "position": "relative"
      }
    }}>{`Communicate with the guest OS (SSH)`}<a parentName="h3" {...{
        "href": "#communicate-with-the-guest-os-ssh",
        "aria-label": "communicate with the guest os ssh permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`SSH provides secure login to remote machines over a network, and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Secure_Shell#Uses"
      }}>{`more`}</a>{`. From the VirtualBox Manager start the Ubuntu VM (graphically) and open the terminal application in Ubuntu. `}</p>
    <p><strong parentName="p">{`Install the OpenSSH daemon/server (sshd)`}</strong>{` with the command `}<inlineCode parentName="p">{`sudo apt-get install openssh-server`}</inlineCode>{`. Then run the following commands to - enable SSH, `}<strong parentName="p">{`start the SSH daemon`}</strong>{`, and check it’s status. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Check the Ubuntu OS version per the output of the `}<inlineCode parentName="p">{`cat /etc/*release*`}</inlineCode>{` command.`}</p>
    </blockquote>
    <div className="gap-20"></div>
    <h5 {...{
      "id": "ubuntu-version-1604-lts-or-newer",
      "style": {
        "position": "relative"
      }
    }}>{`Ubuntu version 16.04 LTS or newer`}<a parentName="h5" {...{
        "href": "#ubuntu-version-1604-lts-or-newer",
        "aria-label": "ubuntu version 1604 lts or newer permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h5>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`sudo systemctl enable ssh`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`sudo systemctl start ssh`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`sudo systemctl status ssh`}</inlineCode></li>
    </ol>
    <div className="gap-10"></div>
    <h5 {...{
      "id": "ubuntu-versions-14044-lts",
      "style": {
        "position": "relative"
      }
    }}>{`Ubuntu versions 14.04.4 LTS`}<a parentName="h5" {...{
        "href": "#ubuntu-versions-14044-lts",
        "aria-label": "ubuntu versions 14044 lts permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h5>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`sudo service ssh start`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`sudo service ssh status`}</inlineCode></li>
    </ol>
    <div className="gap-20"></div>
    <p>{`Allow SSH through `}<strong parentName="p">{`the Ubuntu firewall (ufw)`}</strong>{`, if active. Check firewall status with the command `}<inlineCode parentName="p">{`sudo ufw status`}</inlineCode>{`. Allow SSH through the firewall (port 22) using the command `}<inlineCode parentName="p">{`sudo ufw allow ssh`}</inlineCode>{`.`}</p>
    <p>{`To find the guest OS on the network we can use its IP address or use port forwarding to send all traffic from host to guest via a designated port.`}</p>
    <div className="gap-20"></div>
    <h5 {...{
      "id": "connect-via-port-forwarding",
      "style": {
        "position": "relative"
      }
    }}>{`Connect via port forwarding`}<a parentName="h5" {...{
        "href": "#connect-via-port-forwarding",
        "aria-label": "connect via port forwarding permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h5>
    <p>{`Select `}<em parentName="p">{`settings`}</em>{` from the VirtualBox manager then `}<em parentName="p">{`network -> advanced -> port forwarding`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8e59802729a5b62b7b2cc4a4d1a292ca/a8979/manager-window-settings.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACq0lEQVQ4y1WTO28TQRSF/T8o6Kmo4Q8hGihokJCoUoIEFQJERYsilEQKIMU4QSKO1/bOrvfpfcy+n7bjIGE7ETrcmUAClq7Go5n57jn33u0YhgHHdeFOp3DdKaauA+b66DshNKZCVXV8Y58xYN9h2y6m8p57FY7jwDItOrPlvqMoA4RTF7ahYeIY9N+Bbtk4HNKejWFOJhioh9D0Ed0xYVmWhAiAAIVhiKZtUBSFTNb5+KWH/Z6C7RMPPS/EnlGga2awwhiKF+F9T8fOYAo9zHBgRUjyEsOgRF5WWCzmUIdjfNrZh6EbEtzpMhe7RxPYfgQ/zuBGCRS7wP6wghGWpNaBE0ZIi4rOE+RCScCR5zl+btZ4vfUctzs38PjeI5z+WKLTV6hO4yHyLCX5ARLuY6Bz7B5XML0CbZXTWY40TVFkGZIkQZFniKMIVdti++Ur3L95B88ePsVsuaAaMgMTXUccxzJMqotOtSsSH7O2lrbMsIXHM2SUVIBlCHDToPv2DbZu3cWLB0/QnhIwIoimMYKRLVLg+QEYE3tSVJSy2HleSKvC5r8h7pujIbSDr3B1E1VdocM5jYemSXUR2TCpixPqbBCEsoMiOOdyFcrK8jKJWIXKlMC/cPmrKgnkV0DxSIyECKFYPGypTrPZ7Gqdz+cyGtpHBBTvz5ZLrFYrmeQ/oFAgFVFzVItjNFZhGBO6WBFwgbpupYqarGVUBp/HskkCdnFxgYZqegUUdkX8bU6SUj09j4AmrSZ9BX0a3LFUxOleEnM0RYQw8LFcnkngZrO5BtZ1La0IWzNhj0LYnM9PqZ4MivKBwEfSalOXCNIWXf0MmuVjMW8Jdn4N1GlsFEVBv9+XcTJQMFIZfcsMjCZAJGRM/xMaRsMBDo8Z3u3RiNkc55sV1uu1BP4GF4acUl/pk5wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Settings window for port forwarding",
            "title": "Settings window for port forwarding",
            "src": "/static/8e59802729a5b62b7b2cc4a4d1a292ca/0a47e/manager-window-settings.png",
            "srcSet": ["/static/8e59802729a5b62b7b2cc4a4d1a292ca/8a4e8/manager-window-settings.png 150w", "/static/8e59802729a5b62b7b2cc4a4d1a292ca/5a46d/manager-window-settings.png 300w", "/static/8e59802729a5b62b7b2cc4a4d1a292ca/0a47e/manager-window-settings.png 600w", "/static/8e59802729a5b62b7b2cc4a4d1a292ca/1cfc2/manager-window-settings.png 900w", "/static/8e59802729a5b62b7b2cc4a4d1a292ca/c1b63/manager-window-settings.png 1200w", "/static/8e59802729a5b62b7b2cc4a4d1a292ca/a8979/manager-window-settings.png 1828w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a new port forwarding rule that maps host OS port 2222 to guest OS port 22 such that;`}</p>
    <ul>
      <li parentName="ul">{`Protocol: TCP`}</li>
      <li parentName="ul">{`Host IP: 127.0.0.1`}</li>
      <li parentName="ul">{`Host Port: 2222`}</li>
      <li parentName="ul">{`Guest IP: `}<em parentName="li">{`N/A (empty)`}</em></li>
      <li parentName="ul">{`Guest Port: 22`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b9bc350866e111ecb357e936f6944c1f/de766/manager-window-settings-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACNElEQVQ4y52SS08TYRSGJ/4Ql66Nf0F/j4kb3bgwRpZuXLhw4SUaMBEqIZZCqKjYyGVKbWnnPp3p0Gk7CL1xqb0/fjOCkGAUfZM3Mzk5ec53LpKiqJimKWxFtkyDnFlk3XDZymXJZvOkcovIuVUM3cSyrOP8nzYMA03V0HWd2LMppLQs49oWmq6gCLuWQV7TSWW2KOS+ohYKyNkVtvIZdFFc07QIEgJUVaXkldit71Gr1Zh+Ook0t/SJ+Mc0M5suK5ZLQg1IFirkbJ91u8xUSiG+WSTvVvmgb+PXAjLODpXgG41GE/nLOonZeTRFY34yhrSc1Vlc1bFL5ciK7WJ7FUzHwy0LsCracj1KZR9TfL3tbQzbobVbYTg84PWT51y7dJl7t+6yLMDS6kYGU1dp1Ot87xxxuN9mNBwQiBYOxH9QrdJqNqj4PvvtNq1Wi373iIkXBg/f5DHTn7l55QaP7jwg+TaOJGcLYtAm3W6PE43H48ihRqNR5LOxULLZIet2sNdSTFy9zuPb90nG3iG5JQ/bthkMBr9gZ8FndQI9jY/wXJf8SgpHXEBicgbJcZxzwIsZ0VWXYGeH4TF+4dX074F/00lOr9+nKmbcFnMN4YmXb/7/hRGw1yMIAjqdjtj4kKWp2Hngvyh8oe9XaIvtDwRwI548BYbVwm2GlS7i8Vhsf9AV51SmLk6uL+By/L3YsthSsViMgo1G44+OcppNalUf199jLn1IwdmjJ+6y3x+wNrvAD3DVuzosipmbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Port forwarding rules window",
            "title": "Port forwarding rules window",
            "src": "/static/b9bc350866e111ecb357e936f6944c1f/0a47e/manager-window-settings-2.png",
            "srcSet": ["/static/b9bc350866e111ecb357e936f6944c1f/8a4e8/manager-window-settings-2.png 150w", "/static/b9bc350866e111ecb357e936f6944c1f/5a46d/manager-window-settings-2.png 300w", "/static/b9bc350866e111ecb357e936f6944c1f/0a47e/manager-window-settings-2.png 600w", "/static/b9bc350866e111ecb357e936f6944c1f/1cfc2/manager-window-settings-2.png 900w", "/static/b9bc350866e111ecb357e936f6944c1f/c1b63/manager-window-settings-2.png 1200w", "/static/b9bc350866e111ecb357e936f6944c1f/de766/manager-window-settings-2.png 1838w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`From the host OS - open a terminal/command prompt window and `}<strong parentName="p">{`SSH to the guest OS`}</strong>{`; `}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "c",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ssh <guest username>@localhost -p `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`2222`}</span></span></span></code></pre>
    <h5 {...{
      "id": "connect-via-ip-address-optional",
      "style": {
        "position": "relative"
      }
    }}>{`Connect via IP address (optional)`}<a parentName="h5" {...{
        "href": "#connect-via-ip-address-optional",
        "aria-label": "connect via ip address optional permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h5>
    <p>{`If port forwarding doesn’t work or you prefer to connect via IP address, find the guest OS IP from the output of the `}<inlineCode parentName="p">{`ifconfig`}</inlineCode>{` command. Open the `}<em parentName="p">{`settings`}</em>{` window, shown above, select `}<em parentName="p">{`network -> attached to -> host-only adapter`}</em>{` save selection. Using the guest OS IP address, create a SSH connection;`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "c",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ssh <guest os username>@<guest os ip>`}</span></span></span></code></pre>
    <h3 {...{
      "id": "login-with-ssh-keys",
      "style": {
        "position": "relative"
      }
    }}>{`Login with SSH keys`}<a parentName="h3" {...{
        "href": "#login-with-ssh-keys",
        "aria-label": "login with ssh keys permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Copy your public key to the guest/remote host using `}<inlineCode parentName="p">{`ssh-copy-id`}</inlineCode>{`. See references for generating a `}<a parentName="p" {...{
        "href": "https://help.github.com/en/github/authenticating-to-github/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent#generating-a-new-ssh-key"
      }}>{`RSA key pair`}</a>{`. To facilitate the use of scripts, don’t include a passphrase while generating keys. `}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "c",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ssh-copy-id <remote username>@<remote ip> -i ~/.ssh/<public key>.pub`}</span></span></span></code></pre>
    <p>{`Example command for a guest OS running in VirtualBox:`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "c",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`ssh-copy-id ubuntu@localhost -i ~/.ssh/gen-nopass_ras.pub -p `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`2222`}</span></span></span></code></pre>
    <p>{`User authorization with RSA public key has been achieved. The command `}<inlineCode parentName="p">{`ssh ubuntu@localhost -p 2222`}</inlineCode>{` no longer prompts for a password.`}</p>
    <div className="gap-30"></div>
    <blockquote>
      <h2 parentName="blockquote" {...{
        "id": "references",
        "style": {
          "position": "relative"
        }
      }}>{`References`}<a parentName="h2" {...{
          "href": "#references",
          "aria-label": "references permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h2>
      <ul parentName="blockquote">
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://tools.ietf.org/html/rfc4251"
          }}>{`The Secure Shell Protocol`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://kb.iu.edu/d/aelc"
          }}>{`SSH and SSH2`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.ubuntu18.com/install-openssh-ubuntu-18/"
          }}>{`Ubuntu 18`}</a></li>
      </ul>
    </blockquote>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .dark-default-dark {
    background-color: #1E1E1E;
    color: #D4D4D4;
  }
  .dark-default-dark .mtk1 { color: #D4D4D4; }
  .dark-default-dark .mtk7 { color: #B5CEA8; }
  .dark-default-dark .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      